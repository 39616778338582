.App {
  background-color: black;
  color: white
}

.App-Logo {
  height: 3rem;
  width: 3rem;
  pointer-events: none;
  margin-top: 3rem;
  margin-bottom: 1rem;
  animation: rotate3d 4s infinite cubic-bezier(0.215, 0.610, 0.355, 1);
}

@keyframes rotate3d {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

.App-Container {
  /* Center on the page */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0%;
  left: 1%;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.555);
  padding: 1rem;
  border-radius: 0rem 0rem 1rem 1rem;

}

.App-Container-2 {
  /* Center on the page */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 0%;
  right: 1%;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
  pointer-events: none;
  background-color: transparent;
  padding: 1rem;
  user-select: none;
  pointer-events: none;
}

.App-Container-Sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  gap: 1rem;
  user-select: none;
  width: 100%;
}

.Spline-Container {
  /* Center on the page */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #000000;   */
  /* border-radius: 1rem; */
  z-index: 1;
}

.Mobile-Warning {
  display: none;
}

.DownloadBTN-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.App-Instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 2;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.452);
  border-radius: 1rem;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* If user is on a mobile device */
@media only screen and (max-width: 600px) {
  .App {
    background-color: black;
    color: white
  }

  .App-Instructions {
    display: none;
  }

  .App-Container {
    /* Center on the page */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: -2%;
    left: 1%;
    /* transform: translate(-50%, -50%); */
    z-index: 2;
    pointer-events: none;
    background-color: transparent;
    padding: 1rem;
    display: none;
  }

  .App-Container-Sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0rem;
  }

  .Spline-Container {
    z-index: 0;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
  }

  .Mobile-Warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0000009c;  
    /* border-radius: 1rem; */
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  body {
    overflow: hidden;
  }
}