body {
  margin: 0;
  font-family: 'Inter', 'Westmount', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: 0.75rem;
}

/* Custom dark mode scrollbar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1e1e1e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add my fonts from the assets/fonts folder */
@font-face {
  font-family: 'Westmount';
  src: url('./assets/fonts/WestmountBold.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter.ttf');
}

h1 {
  font-family: 'Westmount';
  font-size: 2rem;
  color: white;
  margin: 0;
}

h5 {
  font-family: 'Westmount';
  font-size: 0.8rem;
  color: rgb(153, 153, 153);
  margin: 0;

}

p {
  font-family: 'Inter';
  font-size: 0.9rem;
  color: white;
  margin: 0;
}

button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  font-family: 'Inter';
  font-size: 1rem;
  color: white;
  margin: 0;
  background-color: black;
  cursor: pointer;
  border-radius: 0.75rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid white;
}

.disabled {
  filter: blur(2px);
  pointer-events: none;
}